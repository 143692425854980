<template>
  <v-container class="text-center d-flex flex-column">
    <div>
      <div class="d-flex justify-center mt-0">
        <v-img
          position="center center"
          max-width="70%"
          src="@/assets/logo_sekulic2022-retina.png"
        ></v-img>
      </div>
      <h1 class="headline text-center mt-6 grey--text text--dargen-1">
        Bienvenue sur l'application du tournoi Sekulic 2023
      </h1>
      <v-btn class="mt-6 yellow darken-2" to="/settings">
        Choisissez votre équipe favorite
        <v-icon right dark> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-center mt-0 mb-5">
      <v-img max-width="90%" src="@/assets/publicite-mint-retina.png"></v-img>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  data: () => ({
    category: null,
  }),
  created() {
    this.$emit('set-menu', {
      title: 'Sekulic 2023',
      link: '/settings',
      icon: 'mdi-menu',
    });
  },
}
</script>

<style lang="scss" scoped>
.container {
  height: calc(100% - 100px);
  justify-content: space-around;
  background-image: url('../assets/bg-body-retina.jpg');
  background-size: cover;
}
.v-btn {
  text-transform: unset !important;
  letter-spacing: 0 !important;
}
</style>
